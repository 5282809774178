import React from 'react';

export default function Layout({ children }){
    return (
        <div class="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
            <header class="masthead mb-auto">
                <div class="inner">
                    <img src="/assets/logo.svg" className="logo" />
                    <h3 class="masthead-brand ms-2">Heal Sri Lanka</h3>
                    <nav class="nav nav-masthead justify-content-center ms-3">
                        <a class="nav-link active" href="#">HOME</a>
                        <a class="nav-link" href="https://healsrilankanft.com">NFTS</a>
                    </nav>
                </div>
            </header>

            <main role="main" class="content inner cover mt-4">
                <div className="row heading d-flex flex-column align-items-center">
                    <img src="/assets/heal-logo.png" className="logo-heal align-self-center" />
                    <h1 align="center" className="mt-4">Heal Sri Lanka</h1>
                    <h4 align="center" className="mt-1">NFT Collection to Help Sri Lankan Medicine Crisis</h4>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6 p-4 d-flex justify-content-md-end">
                        <div className="nft-card p-4 d-flex flex-column align-items-center rounded-3 h-100 justify-content-center">
                            <img className="align-self-center" src="/assets/nfts.gif" width="50%" />
                            {/* <p style={{ fontSize: "0.9em" }}>x950 remaining</p> */}
                            <p>
                            Heal Sri Lanka is a limited NFT Collection, launched to help the Sri Lankan Medical Crisis of 2022, featuring popular creatives inspired by the peaceful protests.
                            90% of mint proceeds go to the cause and 10% goes to the creator. 100% of royalties goes towards the cause. <br />
                            Find out more at <a href="healsrilankanft.com">healsrilankanft.com</a> <br />
                            Be a part of this moment as we invite you to purchase from the Heal Sri Lanka limited NFT collection.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 p-4">
                        <div className="nft-card rounded-3 p-4 h-100">
                            <div className="p-4 d-flex flex-column align-items-center w-100">
                                <h3>Heal Sri lanka NFT Collection</h3>

                                <span style={{ fontSize: "0.7rem" }}>@hyperglade</span>

                                <hr />

                                <div className="w-100">
                                    <span className="w-100" style={{ fontSize: "0.8em" }}>Collection</span>
                                    <div className="d-flex w-100 align-items-center mt-2 w-100">
                                        <img src="/assets/heal-logo.png" width="50px" height="45px" />
                                        <div className="ms-2">
                                            <div style={{ fontSize: "0.8em" }}>
                                                <b>Heal Sri Lanka Collection</b> <br />
                                                #10,000 NFTs to help SL during the crisis
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                <div className="d-flex justify-content-between w-100">
                                    <div className="text-left">
                                        <p style={{ fontSize: "0.8em" }}>
                                            Quantity:<br />
                                            Cost:<br />
                                            Fees:
                                        </p>
                                        <h4>Total</h4>
                                    </div>
                                    <div className="text-right">
                                        <p style={{ fontSize: "0.8em", textAlign: 'right' }}>
                                            x1<br />
                                            2.0 SOL<br />
                                            Gas Fees Apply
                                        </p>
                                        <h4 align='right'>2.0 SOL</h4>
                                    </div>
                                </div>

                                <hr />

                                <div className="w-100">
                                    {children}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    ) 
}
