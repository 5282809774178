import React from 'react';
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

export function CrossMintPay() {
    return (
        <div className="w-100 d-flex justify-content-center">
            <CrossmintPayButton
                collectionTitle="Heal Sri Lanka"
                collectionDescription="Heal Sri Lanka is a limited NFT Collection, launched to help the Sri Lankan Medical Crisis of 2022, featuring popular creatives inspired by the peaceful protests. 90% of mint proceeds go to the cause and 10% goes to the creator. 100% of royalties goes towards the cause. Find out more at healsrilankanft.com."
                collectionPhoto="https://mint.healsrilankanft.com/assets/nfts.gif"
                clientId="7607dc99-3976-47e7-956a-593e5a3155c7"
                
                mintConfig={{"type":"candy-machine"}}
            />
        </div>
    );
}